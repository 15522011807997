<template>
    <div class="login-step min-h-screen flex items-center justify-center py-28 lg:py-36 px-4">
        <div class="text-center flex flex-col items-center text-white max-w-3xl">
            <p class="overline">Identification du professionnel</p>
            <h1 class="h2 mt-4">Valider votre identification</h1>
            <p class="body-1 mt-6">
                Si vous êtes pharmacien, entrez votre CIP.<br>
                Si vous êtes professionnel hospitalier, libéral ou une association, entrez votre email.<br>
            </p>
            <form ref="form" @submit.prevent="getHealthProfessional" class="flex flex-col items-center lg:flex-row lg:items-stretch mt-4">
                <csrf-input></csrf-input>
                <input
                    type="text"
                    name="healthProfessionalId"
                    class="input-text w-56 lg:w-75"
                    placeholder="Votre CIP ou adresse email"
                >
                <button type="submit" class="btn btn--white mt-4 lg:mt-0">Valider</button>
            </form>
            <p v-if="errorMessage" class="text-alert mt-2 body-2">{{ errorMessage }}</p>
            <p class="caption mt-6">Le code CIP ou Code Identifiant de Présentation est un code numérique à 7 ou 13 chiffres qui permet d’identifier votre pharmacie.</p>
            <p class="mt-4 caption">
                Les collaborateurs dédiés de la société Roche Diabetes Care France, domiciliée 2, avenue du Vercors, 38240 Meylan, France ("Roche"), traiteront cet élément sur la base de l'intérêt légitime dans le cadre du service "Pack sensibilisation diabète", pour une durée allant jusqu'à l'issue dudit service, à des fins d'identification des organismes participants. Vous pouvez exercer vos droits sur vos données personnelles en contactant france.diabetescare-rgpd@roche.com. Pour toute réclamation : www.cnil.fr. Une fois cette étape passée, votre navigation sera totalement privée.
            </p>
        </div>
    </div>
</template>

<script>
import CsrfInput from "@/app/components/CsrfInput";
import axios from "axios";

export default {
    name: "LoginStep",

    props: {
        siteUrl: {
            type: String,
            required: true,
        }
    },

    components: {
        CsrfInput,
    },

    emits: ['loggedIn'],

    data() {
        return {
            errorMessage: null,
        }
    },

    methods: {
        getHealthProfessional() {
            this.errorMessage = null;
            const payload = new FormData(this.$refs.form);

            axios.post(`${this.siteUrl}get-health-professional`, payload, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                if (response.data.success) {
                    this.$emit('loggedIn', response.data.entryId);
                } else {
                    this.errorMessage = response.data.message;
                }
            }).catch((error) => {
                this.errorMessage = error.response.data.message;
            });
        },
    },
}
</script>

<style scoped lang="scss">
    .login-step {

    }
</style>
