<template>
    <a href="#" class="hero-anchor overline-small flex flex-col items-center" @click.prevent="scrollTo">
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "HeroAnchor",

    props: {
        text: {
            type: String,
            required: true,
        },
    },

    methods: {
        scrollTo() {
            document.querySelector('.hero').nextElementSibling.scrollIntoView();
        }
    },
}
</script>

<style scoped lang="scss">
    .hero-anchor {
        &::after {
            @apply w-px h-10 bg-center bg-cover mt-4.5;
            content: '';
            background-image: url('~@/static/images/icons/gradient-white.svg');
        }
    }
</style>
