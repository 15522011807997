<template>
    <div class="single-choice-step max-w-3xl">
        <p v-if="step.overline" class="overline mb-4 text-white text-center">{{ step.overline }}</p>
        <h2 ref="title" class="text-white text-center" v-html="step.question" />
        <p v-if="step.text" ref="text" class="body-1 mt-6 text-white text-center opacity-0">{{ step.text }}</p>
        <div class="single-choice-step__choices mt-3">
            <fieldset class="flex flex-col md:flex-row flex-wrap justify-center items-center max-w-xl mx-auto">
                <template v-for="(input, index, key) in choices" :key="key">
                    <input
                        type="radio"
                        :id="input.id"
                        :name="input.name"
                        :value="input.value"
                        v-model="radioValue"
                        class="hidden"
                    />
                    <label
                        ref="buttons"
                        :for="input.id"
                        :class="{
                            'flex flex-col items-center justify-center': input.icon,
                            'md:rounded-full': !input.icon,
                            'btn--ghost-white': radioValue !== input.value,
                            'btn--white': radioValue === input.value,
                            'md:ml-3': index > 0
                        }"
                        class="btn cursor-pointer md:whitespace-nowrap justify-center w-full md:w-auto mt-2 md:mt-5 opacity-0"
                    >
                        <object v-if="input.icon" type="image/svg+xml" :data="require(`@/static/images/icons/${input.icon}`)" class="w-6 h-6 mb-2" />
                        {{ input.text }}
                    </label>
                </template>
            </fieldset>
        </div>
        <div ref="optBtns" class="single-choice-step__buttons flex flex-col items-center opacity-0">
            <button
                v-if="step.extra && step.extraButtonLabel"
                class="single-choice-step__extra-btn btn-text btn-text--white mt-8 md:mt-10"
                @click="goToExtra"
            >
                {{ step.extraButtonLabel }}
            </button>
            <button
                v-if="lastStep && step.resultButton"
                class="btn btn--white mt-6"
                :disabled="!radioValue"
                @click="seeResults"
            >
                Voir les résultats
            </button>
        </div>

        <div v-if="step.additionalText" class="lead text-white text-center mt-6 md:mt-20">
            {{ step.additionalText }}
        </div>
    </div>
</template>

<script>
    import gsap from '@lahautesociete/gsap';
    import { SplitText } from '@lahautesociete/gsap/SplitText';
    gsap.registerPlugin(SplitText);

    export default {
        props: {
            step: { type: Object, default: () => {} },
            choicesIndex: { type: Number, default: null },
            lastStep: { type: Boolean, default: false }
        },

        data() {
            return {
                radioValue: null
            }
        },

        computed: {
            choices() {
                return (this.choicesIndex !== null) ? this.step.choices[this.choicesIndex]:  this.step.choices;
            }
        },

        watch: {
            radioValue() {
                if (!this.lastStep || !this.step.resultButton) {
                    this.animLeave();
                }
            }
        },

        mounted() {
            this.split = new SplitText(this.$refs.title, { type : 'lines, words', linesClass: 'line', wordsClass : 'word'});

            gsap.set(this.split.words, {
                y : 100
            });

            this.animEnter();
        },

        methods: {
            seeResults() {
                this.animLeave();
            },

            goToExtra() {
                this.$emit('scroll-to-extra');
            },

            animEnter() {
                Array.prototype.forEach.call(this.split.lines, function(el, i ){
                    gsap.to(el.querySelectorAll('.word'),{
                        y: 0,
                        duration : 0.9,
                        delay : i * 0.2,
                        ease : 'power3.out'
                    });
                }.bind(this));

                gsap.fromTo(this.$refs.text, {
                    y: 40,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.8,
                    ease: 'power3.out',
                });

                gsap.fromTo(this.$refs.buttons, {
                    y: 40,
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.8,
                    stagger: 0.2,
                    ease: 'power3.out',
                });

                gsap.fromTo(this.$refs.optBtns, {
                    y: 40,
                }, {
                    y: 0,
                    opacity: 1,
                    duration: 0.8,
                    stagger: 0.2,
                    ease: 'power3.out',
                });
            },

            animLeave() {
                if (this.radioValue === 'to-final-step') {
                    this.radioValue = null;
                    this.$emit('final-step');
                    return;
                }

                Array.prototype.forEach.call(this.split.lines, function(el){
                    gsap.to(el.querySelectorAll('.word'),{
                        y: 100,
                        duration : 0.7,
                        ease : 'power3.out'
                    });
                }.bind(this));

                gsap.to(this.$refs.text, {
                    y: 20,
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power3.out'
                });

                gsap.to(this.$refs.optBtns, {
                    y: 20,
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power3.out'
                });

                gsap.to(this.$refs.buttons, {
                    y: 30,
                    opacity: 0,
                    duration: 0.3,
                    stagger: 0.1,
                    ease: 'power3.out',
                    onComplete: () => {
                        const selectedChoice = (this.choicesIndex !== null) ?
                            this.step.choices[this.choicesIndex].filter(pChoice => pChoice.value === this.radioValue)
                            : this.step.choices.filter(pChoice => pChoice.value === this.radioValue)

                        this.$emit('step-done', selectedChoice);
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .single-choice-step {
        .btn {
            &:hover {
                object {
                    filter: invert(50%);
                }
            }
        }

        &__extra-btn {
            font-size: 0.875rem;
        }

        .line {
            @apply overflow-hidden;
        }
    }
</style>
