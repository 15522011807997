<template>
    <div class="overflow-hidden bg-white">
        <div class="poll-result__extra-inner px-4 py-8 lg:py-20">
            <div class="max-w-2xl mx-auto">
                <h2>
                    En savoir plus
                </h2>

                <div class="mt-3 md:mt-10">
                    <accordion>
                        <template v-slot:opener>
                            <p class="overline-serif uppercase">Alimentation</p>
                            <h5 class="mt-2">Je me fais plaisir en mangeant</h5>
                        </template>
                        <template v-slot:content>
                            <div class="body-1">
                                Être en bonne santé passe par une assiette équilibrée et une activité physique régulière. Qu’est-ce que l’équilibre alimentaire ? Comment composer une assiette bonne pour la santé tout en continuant à se faire plaisir ?<br/>
                                Depuis 2001, le Programme National Nutrition Santé (PNNS) (1) créé par l’Agence Nationale de Sécurité sanitaire de l’Alimentation, de l’environnement et du travail (ANSES) a pour objectif d’améliorer l’état nutritionnel de la population. Ses dernières recommandations s’articulent autour de trois axes : <br/><br/>
                                1 - augmenter la consommation de fruits et légumes (au moins 5 portions par jour) et de légumineuses (lentilles, pois chiches…) pour leur richesse en fibres, vitamines et minéraux ; <br/><br/>
                                2 - privilégier et encourager les féculents complets (riz brun, pain aux céréales, etc.),  pour leurs fibres et l’énergie lente apportées dans l’organisme ; consommer du poisson pour ses protéines et ses acides gras polyinsaturés bénéfiques, dont les oméga-3 dans les poissons gras ; ajouter des produits laitiers pour le calcium essentiel à la solidité des os et des dents.<br/><br/>
                                3 - réduire la charcuterie, les boissons sucrées et/ou alcoolisées, entre autres, car elles contiennent des graisses, du sucre ou du sel dans des proportions qui peuvent être néfastes pour la santé. <br/>
                                D’autres paramètres ne sont pas encore pris en compte par l’ANSES mais pourraient, dans les prochaines années, devenir incontournables pour favoriser une alimentation saine et équilibrée mais aussi durable, contribuant à la préservation de notre planète. De nombreuses études ont démontré, par exemple, que la viande rouge (2) a un impact carbone très élevé. À l’inverse, les produits bruts tels que les céréales entières, les légumes, les fruits, ont un impact environnemental nettement plus faible. Le choix de produits de saison, locaux ou de circuits courts fait partie intégrante de l’équilibre alimentaire.<br/><br/>
                                Certains repas sont plus équilibrés que d’autres selon les jours et les circonstances. Dites-vous que l’équilibre se fait sur une semaine et non à chaque repas, chaque jour. Aucun aliment n’est interdit. Il s’agit plutôt d’ajuster la quantité, de modérer la fréquence de consommation de certains aliments et d’éviter les excès. Pour y arriver, vous pouvez par exemple planifier vos menus, préparer vos repas à l’avance pour ne pas être tentés par le contenu du réfrigérateur, éviter de remplir votre chariot de courses d’aliments transformés, servir les repas à l’assiette, etc. Sans oublier d’équilibrer les apports et les dépenses énergétiques en augmentant tout simplement votre activité physique.<br/><br/>
                                Mais surtout, continuez à vous faire plaisir en mangeant : le plaisir est une notion importante car il est tout à fait possible d’avoir une alimentation équilibrée sans sacrifier le plaisir d’un délicieux repas ou d’un dessert. Manger sainement, ce n’est donc pas suivre une série de restrictions qui deviendront vite intenables. Il s’agit plutôt d’adopter un ensemble de bonnes pratiques qui vont au-delà de l’assiette et vous assureront un bon équilibre au long cours. Par exemple, saisir toutes les occasions de sortir de la sédentarité, marcher, bouger, pratiquer une activité physique qui vous plaise assez pour devenir régulière. <br/><br/>
                                Sources:<br/>
                                ANSES, Mangerbouger, Les recommandations alimentation et activité physique, consulté le 13 septembre 2021 sur <a href="https://www.mangerbouger.fr/Les-recommandations" target="_blank">https://www.mangerbouger.fr/Les-recommandations</a> <br/>
                                Clark M,Springmann et al Multiple health and environmental impacts of foods, Proceedings of the National Academy of Sciences, Nov 2019, 116 (46), 23357-23362 consulté sur <a href="https://www.pnas.org/content/116/46/23357" target="_blank">https://www.pnas.org/content/116/46/23357</a> le 13 septembre 2021<br/>
                            </div>
                        </template>
                    </accordion>
                    <accordion>
                        <template v-slot:opener>
                            <p class="overline-serif uppercase">Sport</p>
                            <h5 class="mt-2">Je me sens en forme</h5>
                        </template>
                        <template v-slot:content>
                            <div class="body-1">
                                L’activité physique correspond à tous les efforts physiques effectués dans la vie quotidienne mais aussi dans le cadre de loisirs ou d’activités sportives.(1)<br/>
                                Bouger un peu tous les jours et ne pas rester assis trop longtemps permet de se sentir mieux dans son corps et dans sa tête. Voici quelques bonnes idées pour vous !<br/><br/>
                                <img :src="require('@/static/images/sport.png')" /> <br/><br/>
                                (1) HAS. Guide de promotion, consultation et prescription médicale d’activité physique et sportive pour la santé chez les adultes. Juillet 2019. Consulté le 01/09/2021 sur <a href="https://www.has-sante.fr/upload/docs/application/pdf/2018-10/guide_aps_vf.pdf" target="_blank">https://www.has-sante.fr/upload/docs/application/pdf/2018-10/guide_aps_vf.pdf</a>
                            </div>
                        </template>
                    </accordion>
                    <accordion>
                        <template v-slot:opener>
                            <p class="overline-serif uppercase">Gestion des émotions</p>
                            <h5 class="mt-2">Je comprends mes émotions</h5>
                        </template>
                        <template v-slot:content>
                            <div class="body-1">
                                Nos humeurs et nos sentiments quotidiens affectent notre corps et peuvent influencer notre manière de manger. Mais comment les comprendre et les accepter pour se sentir mieux ?<br/><br/>
                                Avant de manger ou de grignoter, s’asseoir et évaluer votre niveau de faim et vos émotions.<br/><br/>
                                Tentez de vous concentrer sur les sensations que vous ressentez lorsque vous mangez : le poids du repas dans l’estomac, la saveur et le plaisir des aliments…<br/><br/>
                                Parfois on peut écarter les émotions négatives par des actions quotidiennes qui nous font<br/>
                                du bien : lire un livre, ordonner sa maison, écouter de la musique...<br/>
                            </div>
                        </template>
                    </accordion>
                    <accordion>
                        <template v-slot:opener>
                            <p class="overline-serif uppercase">Organisation</p>
                            <h5 class="mt-2">J'organise mon quotidien</h5>
                        </template>
                        <template v-slot:content>
                            <div class="body-1">
                                Parfois, il suffit de quelques conseils à mettre en place au quotidien pour être mieux dans son<br/>
                                assiette et ses baskets !<br/>
                                <br/>
                                • Je note ce que je mange dans la journée sur un temps donné pour me rendre compte des<br/>
                                quantités et des éventuels grignotages<br/>
                                • J’essaie de faire la différence entre la faim et l’envie de manger (Ennui, stress…) <br/>
                                • J’utilise des petites assiettes bien remplies pour un effet de “plein”<br/>
                                • Je dispose tout le repas devant moi avant de manger<br/>
                                • Je m’autorise des plaisirs occasionnels car ils font du bien au moral<br/>
                                • Je prends mon temps : j’éteins ma télévision, je m’assoie confortablement pour savourer et profiter de mon repas<br/>
                                • J’essaye de repérer ma satiété et prendre le temps de mâcher. Je ne me force pas à terminer mon assiette<br/>
                                • Je prévois des petites boites pour conserver les restes<br/>
                            </div>
                        </template>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "@/app/components/Accordion";

export default {
    name: "ExtraAdvices",

    components: {
        Accordion,
    },

    data() {
        return {
            accordions: [
                {
                    label: "Alimentation",
                    heading: "Je me fais plaisir en mangeant",
                    content: "Être en bonne santé passe par une assiette équilibrée et une activité physique régulière. Qu’est-ce que l’équilibre alimentaire ? Comment composer une assiette bonne pour la santé tout en continuant à se faire plaisir ? \n" +
                        " <br/>" +
                        "Depuis 2001, le Programme National Nutrition Santé (PNNS) (1) créé par l’Agence Nationale de Sécurité sanitaire de l’Alimentation, de l’environnement et du travail (ANSES) a pour objectif d’améliorer l’état nutritionnel de la population. Ses dernières recommandations s’articulent autour de trois axes : <br/>" +
                        " <br/>" +
                        "1 - augmenter la consommation de fruits et légumes (au moins 5 portions par jour) et de légumineuses (lentilles, pois chiches…) pour leur richesse en fibres, vitamines et minéraux ; <br/>" +
                        " <br/>" +
                        "2 - privilégier et encourager les féculents complets (riz brun, pain aux céréales, etc.),  pour leurs fibres et l’énergie lente apportées dans l’organisme ; consommer du poisson pour ses protéines et ses acides gras polyinsaturés bénéfiques, dont les oméga-3 dans les poissons gras ; ajouter des produits laitiers pour le calcium essentiel à la solidité des os et des dents.<br/>" +
                        " <br/>" +
                        "3 - réduire la charcuterie, les boissons sucrées et/ou alcoolisées, entre autres, car elles contiennent des graisses, du sucre ou du sel dans des proportions qui peuvent être néfastes pour la santé. <br/>" +
                        " <br/>" +
                        "D’autres paramètres ne sont pas encore pris en compte par l’ANSES mais pourraient, dans les prochaines années, devenir incontournables pour favoriser une alimentation saine et équilibrée mais aussi durable, contribuant à la préservation de notre planète. De nombreuses études ont démontré, par exemple, que la viande rouge (2) a un impact carbone très élevé. À l’inverse, les produits bruts tels que les céréales entières, les légumes, les fruits, ont un impact environnemental nettement plus faible. Le choix de produits de saison, locaux ou de circuits courts fait partie intégrante de l’équilibre alimentaire.<br/>" +
                        " <br/>" +
                        "Certains repas sont plus équilibrés que d’autres selon les jours et les circonstances. Dites-vous que l’équilibre se fait sur une semaine et non à chaque repas, chaque jour. Aucun aliment n’est interdit. Il s’agit plutôt d’ajuster la quantité, de modérer la fréquence de consommation de certains aliments et d’éviter les excès. Pour y arriver, vous pouvez par exemple planifier vos menus, préparer vos repas à l’avance pour ne pas être tentés par le contenu du réfrigérateur, éviter de remplir votre chariot de courses d’aliments transformés, servir les repas à l’assiette, etc. Sans oublier d’équilibrer les apports et les dépenses énergétiques en augmentant tout simplement votre activité physique.<br/>" +
                        " <br/>" +
                        "Mais surtout, continuez à vous faire plaisir en mangeant : le plaisir est une notion importante car il est tout à fait possible d’avoir une alimentation équilibrée sans sacrifier le plaisir d’un délicieux repas ou d’un dessert. Manger sainement, ce n’est donc pas suivre une série de restrictions qui deviendront vite intenables. Il s’agit plutôt d’adopter un ensemble de bonnes pratiques qui vont au-delà de l’assiette et vous assureront un bon équilibre au long cours. Par exemple, saisir toutes les occasions de sortir de la sédentarité, marcher, bouger, pratiquer une activité physique qui vous plaise assez pour devenir régulière. <br/>" +
                        " <br/>" +
                        "Sources:<br/>" +
                        "ANSES, Mangerbouger, Les recommandations alimentation et activité physique, consulté le 13 septembre 2021 sur https://www.mangerbouger.fr/Les-recommandations <br/>" +
                        "Clark M,Springmann et al Multiple health and environmental impacts of foods, Proceedings of the National Academy of Sciences, Nov 2019, 116 (46), 23357-23362 consulté sur https://www.pnas.org/content/116/46/23357 le 13 septembre 2021<br/>"
                },
                {
                    label: "Sport",
                    heading: "Je me sens en forme",
                    content: "L’activité physique correspond à tous les efforts physiques effectués dans la vie quotidienne mais aussi dans le cadre de loisirs ou d’activités sportives.(1)<br/>" +
                        "Bouger un peu tous les jours et ne pas rester assis trop longtemps permet de se sentir mieux dans son corps et dans sa tête. Voici quelques bonnes idées pour vous !<br/><br/>" +
                        "<img :src=\"require('@/static/images/sport.png')\" /> <br/><br/>" +
                        "(1) HAS. Guide de promotion, consultation et prescription médicale d’activité physique et sportive pour la santé chez les adultes. Juillet 2019. Consulté le 01/09/2021 sur https://www.has-sante.fr/upload/docs/application/pdf/2018-10/guide_aps_vf.pdf"
                },
                {
                    label: "Gestion des émotions",
                    heading: "Je comprends mes émotions",
                    content: "Nos humeurs et nos sentiments quotidiens affectent notre corps et peuvent influencer notre manière de manger. Mais comment les comprendre et les accepter pour se sentir mieux ?<br/>" +
                        "<br/>" +
                        "Avant de manger ou de grignoter, s’asseoir et évaluer votre niveau de faim et vos émotions.<br/>" +
                        "<br/>" +
                        "Tentez de vous concentrer sur les sensations que vous ressentez lorsque vous mangez : le poids du repas dans l’estomac, la saveur et le plaisir des aliments…<br/>" +
                        "<br/>" +
                        "Parfois on peut écarter les émotions négatives par des actions quotidiennes qui nous font<br/>" +
                        "du bien : lire un livre, ordonner sa maison, écouter de la musique...<br/>"
                },
                {
                    label: "Organisation",
                    heading: "J'organise mon quotidien",
                    content: "Parfois, il suffit de quelques conseils à mettre en place au quotidien pour être mieux dans son<br/>" +
                        "assiette et ses baskets !<br/>" +
                        "<br/>" +
                        "• Je note ce que je mange dans la journée sur un temps donné pour me rendre compte des<br/>" +
                        "quantités et des éventuels grignotages<br/>" +
                        "• J’essaie de faire la différence entre la faim et l’envie de manger (Ennui, stress…) <br/>" +
                        "• J’utiliser des petites assiettes bien remplies pour un effet de “plein”<br/>" +
                        "• Je dispose tout le repas devant moi avant de manger<br/>" +
                        "• Je m’autorise des plaisirs occasionnels car ils font du bien au moral<br/>" +
                        "• Je prends mon temps : j’éteins ma télévision, je m’assoie confortablement pour savourer et profiter de mon repas<br/>" +
                        "• J’essaye de repérer ma satiété et prendre le temps de mâcher. Je ne me force pas à terminer mon assiette<br/>" +
                        "• Je prévois des petites boites pour conserver les restes<br/>"
                }
            ]
        }
    }
}
</script>
