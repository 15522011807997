<template>
    <div class="multi-test-form relative">
        <div class="multi-test-form__container min-h-screen background-gradient relative flex flex-col justify-center items-center py-32 lg:py-36 px-4">
            <div class="max-w-3xl mx-auto">
                <h2 ref="title" class="text-white text-center">
                    Ajouter l'ensemble des résultats de la campagne
                </h2>
                <p ref="text" class="body-1 mt-6 text-white text-center">Sur cet écran, veuillez renseigner l'intégralité des résultats de votre campagne de sensibilisation. Cela vous permettra de recevoir un bilan personnalisé !</p>

                <form @submit.prevent="submitForm" class="flex flex-col items-center">
                    <div class="flex justify-center mt-8">
                        <div class="flex flex-col justify-center items-center md:items-end">
                            <div class="w-full md:w-auto  flex flex-col md:flex-row md:items-center">
                                <label class="overline text-white text-center md:text-left">Nombre de tests Findrisc en version papier :</label>
                                <input type="number" v-model="paperTestsNumber" placeholder="Nombre" class="input-text w-full mt-3 md:mt-0 md:ml-6" />
                            </div>
                            <div class="w-full md:w-auto flex flex-col md:flex-row md:items-center mt-6 md:mt-3">
                                <label class="overline text-white text-center md:text-left">Nombre de tests de glycémie capillaire :</label>
                                <input type="number" v-model="capillaryTestsNumber" placeholder="Nombre" class="input-text w-full mt-3 md:mt-0 md:ml-6" />
                            </div>
                            <div class="w-full md:w-auto flex flex-col md:flex-row md:items-center mt-6 md:mt-3">
                                <label class="overline text-white text-center md:text-left">Nombre de tests anormalement élevés :</label>
                                <input type="number" v-model="highValueTestsNumber" placeholder="Nombre" class="input-text w-full mt-3 md:mt-0 md:ml-6" />
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn--white mt-8" :disabled="isSubmitDisabled">Ajouter les résultats</button>
                </form>

                <p v-if="isError" class="text-alert text-center mt-4">Une erreur est survenue. Merci de réessayer.</p>
                <div v-if="isSuccess && !isError" class="flex flex-col items-center mt-4">
                    <p class="text-white text-center">Les résultats ont bien été enregistrés.</p>
                    <a :href="`/resultats`" class="btn btn--white mt-6">Voir les résultats</a>
                    <!--                    <form ref="form" method="post">-->
                    <!--                        <input type="hidden" name="action" value="reporting-module/site/results">-->
                    <!--                        <csrf-input></csrf-input>-->
                    <!--                        <input-->
                    <!--                            type="hidden"-->
                    <!--                            name="healthProfessionalId"-->
                    <!--                            :value="healthProfessionalId"-->
                    <!--                        />-->
                    <!--                        <button type="submit" class="btn btn&#45;&#45;white mt-4">Voir les résultats</button>-->
                    <!--                    </form>-->
                </div>
            </div>
        </div>
        <button class="btn-arrow absolute bottom-2 left-1/2  transform -translate-x-1/2 p-4 mt-2 mb-4" @click.prevent="$emit('previous-step')">
            Étape précédente
        </button>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'MultiTestForm',

        props: {
            healthProfessionalId: {
                type: Number,
                required: true,
            }
        },

        data: () => ({
            paperTestsNumber: null,
            capillaryTestsNumber: null,
            highValueTestsNumber: null,
            isError: false,
            isSuccess: false,
            isLoading: false,
        }),

        computed: {
            isSubmitDisabled() {
                return this.isLoading || (!this.paperTestsNumber && !this.capillaryTestsNumber && !this.highValueTestsNumber);
            },
        },

        methods: {
            submitForm() {
                this.isError = false;
                this.isSuccess = false;
                this.isLoading = true;

                axios.post('/save-poll-responses', {
                    CRAFT_CSRF_TOKEN: window.Craft.csrfTokenValue,
                    paperTestsNumber: this.paperTestsNumber,
                    capillaryTestsNumber: this.capillaryTestsNumber,
                    highValueTestsNumber: this.highValueTestsNumber,
                    healthProfessionalId: this.healthProfessionalId,
                }, {
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).then((response) => {
                    this.isLoading = false;

                    if(response.data.success) {
                        this.paperTestsNumber = null;
                        this.capillaryTestsNumber = null;
                        this.highValueTestsNumber = null;

                        this.isSuccess = true;
                        return;
                    }

                    console.error(response);
                    this.isError = true;
                }).catch((error) => {
                    this.isLoading = false;
                    console.error(error);
                    this.isError = true;
                });
            }
        }
    }
</script>

<style lang="scss">
    .multi-test-form {
        .input-text {
            @screen md {
                max-width: 8.75rem;
            }
        }
    }
</style>
