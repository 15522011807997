<template>
    <div class="accordion border-t border-grey-80">
        <div
            :class="{
                'py-6': small,
                'pt-8 pb-6 md:pt-10 md:pb-8': !small
            }"
            class="accordion__opener relative cursor-pointer"
            @click="toggleAccordion"
        >
            <slot name="opener"></slot>
            <img
                :src="require('@/static/images/icons/chevron-down-black.svg')"
                :class="{
                    'top-1/2 transform -translate-y-1/2': small,
                    'top-8 md:top-auto md:bottom-8': !small,
                    'transform rotate-180': opened
                }"
                class="absolute right-0 transition"
            />
        </div>
        <div ref="content" class="accordion__content h-0 overflow-hidden">
            <div ref="inner" class="accordion__inner pb-8 md:pb-12">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        props: {
            small: { type: Boolean, default: true }
        },

        data() {
            return {
                opened: false
            }
        },

        methods: {
            toggleAccordion() {
                this.opened = !this.opened;

                gsap.to(this.$refs.content, {
                    height: (this.opened) ? this.$refs.inner.clientHeight : 0,
                    duration: 0.6,
                    ease: 'power2.out'
                });
            }
        }
    }
</script>
