import "@fontsource/ibm-plex-sans";
import "./assets/styles/main.scss";

// import 'leaflet/dist/leaflet.css';
// import 'leaflet/dist/leaflet';

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import PatientPoll from "@/app/components/PatientPoll";
import HealthProfessionalPoll from "@/app/components/HealthProfessionalPoll";
import HealthProfessionalOrientation from "@/app/components/HealthProfessionalOrientation";
import HeroAnchor from "@/app/components/HeroAnchor";
import CounterCarousel from "@/app/components/CounterCarousel";
import MultiTestForm from "@/app/components/MultiTestForm";

createApp({
    name: 'App',
    components: {
        CsrfInput,
        PatientPoll,
        HealthProfessionalPoll,
        HeroAnchor,
        CounterCarousel,
        HealthProfessionalOrientation,
        MultiTestForm
    }
}).mount('#app');
