<template>
    <div class="health-professional-poll-result relative">
        <button
            class="btn-picto btn-picto--white absolute top-20 lg:top-8 left-1/2 -translate-x-1/2 lg:transform-none lg:left-10 z-20 whitespace-nowrap"
            @click="restartPoll"
        >
            Faire un nouveau test
        </button>
        <div class="max-w-3xl min-h-screen flex flex-col items-center justify-center text-center text-white mx-auto py-32 lg:py-36 px-4">
            <h2 v-html="content.title"/>
            <p class="body-1 mt-8">
                {{ content.text }}
            </p>

            <p v-if="content.caption" class="caption mt-10">
                {{ content.caption }}
            </p>
            <a v-if="content.link" :href="doctorPdfUrl" target="_blank" class="btn btn--white mt-4">Télécharger le courrier</a>
        </div>
        <extra-advices></extra-advices>
    </div>
</template>

<script>
import ExtraAdvices from "@/app/components/ExtraAdvices";

export default {
    name: "HealthProfessionalPollResult",

    props: {
        doctorPdfUrl: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },

    components: {
        ExtraAdvices,
    },

    methods: {
        restartPoll() {
            this.$emit('restart-poll');
        }
    }
}
</script>

<style scoped lang="scss">

</style>
