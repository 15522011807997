<template>
    <div class="poll-result">
        <div class="poll-result__content relative min-h-screen flex flex-col items-center justify-start md:justify-center background-gradient pb-8 md:pb-0">
            <div class="h-full text-center text-white px-4">
                <div class="max-w-3xl mx-auto">
                    <button
                        ref="restartBtn"
                        class="btn-picto btn-picto--white mt-4 lg:mt-0 lg:absolute lg:top-8 lg:left-10 opacity-0 z-20"
                        @click="restartPoll"
                    >Faire un nouveau test</button>

                    <p class="poll-result__score key-figure mt-8 md:mt-0">
                        <span ref="score" class="inline-block opacity-0">{{ score }}</span>
                        <span ref="slash" class="inline-block relative w-px h-18 bg-white transform rotate-45 origin-bottom top-4 -ml-1 opacity-0" />
                        <span ref="maxScore" class="inline-block relative top-4 ml-12 opacity-0">{{ maxScore }}</span>
                    </p>
                    <p ref="label" class="overline mt-10 md:mt-16 opacity-0">Résultats du test</p>
                    <h2 ref="heading" class="max-w-lg mx-auto mt-3">
                        <div class="overflow-hidden">
                            <div class="poll-result__line">
                                <em v-html="currentText.heading"></em>
                            </div>
                        </div>
                        <div class="overflow-hidden">
                            <div class="poll-result__line">
                                de développer un diabète
                            </div>
                        </div>
                    </h2>
                    <p ref="text" class="body-2 mt-4 md:mt-6 opacity-0">
                        {{ currentText.text }}
                    </p>

                    <button
                        v-if="!highScore"
                        ref="lowScoreBtn"
                        class="btn btn--white mt-8 opacity-0"
                        @click="scrollToExtra"
                    >
                        En savoir plus
                    </button>
                </div>

                <div v-if="highScore" class="max-w-4xl mx-auto">
                    <hr ref="sep" class="my-6 md:my-12 border-t border-white w-full opacity-0" />
                    <div class="flex flex-col items-center md:flex-row md:justify-start md:items-start">
                        <h5 ref="highScoreHeading" class="opacity-0">
                            <em>Notre conseil pour affiner le risque</em><br/>
                            effectuer un test de glycémie
                        </h5>
                        <div ref="highScoreText" class="text-center md:text-left mt-2 md:ml-12 opacity-0">
                            <p class="body-2">Rendez-vous chez le professionnel de santé partenaire le plus proche.</p>
                            <button class="btn btn--white mt-4" @click="goToPharma">Trouver un partenaire</button>
                        </div>
                    </div>
                </div>
                <div class="max-w-4xl mx-auto pb-12">
                    <hr ref="sep2" class="my-6 md:my-12 border-t border-white w-full opacity-0" />
                    <div ref="contentEnd" class="flex flex-col md:flex-row text-center md:text-left opacity-0">
                        <div class="md:w-1/2">
                            <h5>
                                <em>Diffusez ce test,</em><br/>
                                partagez-le à vos proches
                            </h5>
                            <p class="body-2 mt-3">
                                Vous permettrez ainsi de sensibiliser le plus grand nombre, en partageant ce test par mail, sur WhatsApp ou en copiant le lien du site.
                            </p>
                            <div class="flex justify-center items-center md:justify-start mt-4">
                                <p class="overline text-white mr-6 md:mr-8">Partager</p>
                                <a href="mailto:?subject=Mon test diabète - Faites le test avec nos 9 questions…&amp;body=https://www.montestdiabete.fr/" target="_blank" class="share-btn">
                                    <img src="~@/static/images/icons/email.svg" alt="Email"/>
                                </a>
                                <a href="whatsapp://send?text=https://www.montestdiabete.fr/" target="_blank" class="share-btn ml-3 md:ml-4">
                                    <img src="~@/static/images/icons/whatsapp.svg" alt="WhatsApp" />
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.montestdiabete.fr/" target="_blank" class="share-btn ml-3 md:ml-4">
                                    <img src="~@/static/images/icons/facebook.svg" alt="Facebook" />
                                </a>
                                <div class="relative ml-3 md:ml-4">
                                    <button class="share-btn" @click.prevent="copyToClipboard('https://www.montestdiabete.fr/')">
                                        <img src="~@/static/images/icons/copy.svg" alt="Copier" />
                                    </button>
                                    <p ref="clipboardResult" class="caption uppercase absolute -bottom-6 left-1/2 transform -translate-x-1/2 whitespace-nowrap"></p>
                                </div>
                            </div>
                        </div>
                        <div class="md:w-1/2 mt-10 md:mt-0 md:ml-6">
                            <h5>
                                <em>Vous souhaitez</em><br/>
                                en savoir plus sur le diabète ?
                            </h5>
                            <p class="body-2 mt-3">
                                Inscrivez-vous gratuitement à notre communauté Bien Vivre mon diabète et recevez régulièrement des conseils pour gérer au mieux le diabète
                            </p>
                            <a href="https://go.roche.com/communaute-bienvivremondiabete " target="_blank" class="btn btn--white mt-4">Recevoir des informations de Roche</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <extra-advices ref="extra"></extra-advices>
    </div>
</template>

<script>
    import ExtraAdvices from "@/app/components/ExtraAdvices";
    import gsap from '@lahautesociete/gsap';
    import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';
    gsap.registerPlugin(ScrollToPlugin);

    export default {
        props: {
            score: { type: Number, default: -1 },
            maxScore: { type: Number, default: -1 },
        },

        components: {
            ExtraAdvices
        },

        data() {
            return {
                scoreLimit: 12,

                veryLowScoreText: {
                    heading: "Risque&nbsp;peu&nbsp;élevé",
                    text: "On considère que votre risque de développer un diabète dans les 10 ans à venir, c’est-à- dire d’avoir un taux de sucre trop élevé dans le sang, est faible, autour de 1%. À ce stade, il n’est pas nécessaire de faire plus d’investigations. N’hésitez pas à repasser ce test si votre situation évolue (nouvelle pathologie, changement de mode de vie...)."
                },

                lowScoreText: {
                    heading: "Risque&nbsp;légèrement&nbsp;élevé",
                    text: "Votre valeur est normale. On considère que votre risque de développer un diabète dans les 10 ans à venir, c’est-à-dire d’avoir un taux de sucre trop élevé dans le sang, est légèrement élevé, autour de 4%. A ce stade, on considère qu’il n’est pas nécessaire de faire plus d’investigations. N’hésitez pas à repasser ce test si votre situation évolue."
                },

                mediumScoreText: {
                    heading: "Risque&nbsp;modéré",
                    text: "On considère que votre risque de développer un diabète dans les 10 ans à venir, c’est-à-dire d’avoir un taux de sucre trop élevé dans le sang, est modéré. Pour affiner le risque, je vous propose de poursuivre par une mesure de glycémie capillaire."
                },

                highScoreText: {
                    heading: "Risque&nbsp;élevé",
                    text: "On considère que votre risque de développer un diabète dans les 10 ans à venir, c’est-à-dire d’avoir un taux de sucre trop élevé dans le sang, est élevé. Pour affiner le risque, nous vous proposons de poursuivre par une mesure de glycémie capillaire auprès d'un professionnel de santé. "
                },

                veryHighScoreText: {
                    heading: "Risque&nbsp;très&nbsp;élevé",
                    text: "Votre score est supérieur à 20. On considère que votre risque de développer un diabète dans les 10 ans à venir, c’est-à- dire d’avoir un taux de sucre trop élevé dans le sang, est très élevé. Pour affiner le risque, nous vous proposons de poursuivre par une mesure de glycémie capillaire auprès d'un professionnel de santé. "
                },

                accordions: [
                    {
                        label: "Alimentation",
                        heading: "Je me fais plaisir en mangeant",
                        content: "Les recommandations en matière d’alimentation sont les mêmes pour les personnes diabétiques que pour le reste de la population. Contrairement aux idées reçues, il n’existe pas de « régime diabétique »."
                    },
                    {
                        label: "Sport",
                        heading: "Je me sens en forme",
                        content: "L’activité physique correspond à tous les efforts physiques effectués dans la vie quotidienne mais aussi dans le cadre de loisirs ou d’activités sportives.(1)"
                    },
                    {
                        label: "Gestion des émotions",
                        heading: "Je comprends mes émotions",
                        content: "Nos humeurs et nos sentiments quotidiens affectent notre corps et peuvent influencer notre manière de manger. Mais comment les comprendre et les accepter pour se sentir mieux ?"
                    },
                    {
                        label: "Organisation",
                        heading: "J'organise mon quotidien",
                        content: "Parfois, il suffit de quelques conseils à mettre en place au quotidien pour être mieux dans son assiette et ses baskets !"
                    }
                ]
            }
        },

        computed: {
            highScore() {
                // Is the score higher than the limit
                return this.score >= this.scoreLimit;
            },

            currentText() {
                let text = null;

                if (this.score < 7) {
                    text = this.veryLowScoreText;
                } else if (this.score >= 7 && this.score <= 11) {
                    text = this.lowScoreText;
                } else if (this.score >= 12 && this.score <= 14) {
                    text = this.mediumScoreText;
                } else if (this.score >= 15 && this.score <= 20) {
                    text = this.highScoreText;
                } else if (this.score >= 20) {
                    text = this.veryHighScoreText;
                }

                // Return high score text of low score text within the actual score
                return text;
            }
        },

        watch: {
            score() {
                if (this.score >= 0) {
                    gsap.set(document.querySelectorAll('.poll-result__line'), {
                        y : 100
                    });

                    // Init timeline
                    this.tl = gsap.timeline();

                    // Show score
                    this.tl.fromTo(this.$refs.score, {
                        y: 20
                    }, {
                        y: 0,
                        opacity: 1,
                        duration: 0.3,
                        delay: 0.5,
                        ease: 'power3.out'
                    });

                    // Show slash
                    this.tl.fromTo(this.$refs.slash, {
                        y: 20
                    }, {
                        y: 0,
                        opacity: 1,
                        duration: 0.3,
                        ease: 'power3.out'
                    }, '-=0.1');

                    // Show max score
                    this.tl.fromTo(this.$refs.maxScore, {
                        y: 20
                    }, {
                        y: 0,
                        opacity: 1,
                        duration: 0.3,
                        ease: 'power3.out'
                    }, '-=0.1');

                    // Show label
                    this.tl.to(this.$refs.label, {
                        opacity: 1,
                        duration: 0.6,
                        ease: 'power3.out'
                    }, '-=0.1');

                    // Show heading lines
                    this.tl.to(this.$el.querySelectorAll('.poll-result__line'),{
                        y: 0,
                        duration : 0.5,
                        stagger : 0.1,
                        ease : 'power3.out'
                    }, '-=0.1');

                    // Show text
                    this.tl.fromTo(this.$refs.text, {
                        y: 20
                    }, {
                        y: 0,
                        opacity: 1,
                        duration: 0.6,
                        ease: 'power3.out'
                    }, '-=0.2');

                    // Show restart button
                    this.tl.fromTo(this.$refs.restartBtn, {
                        scale: 1.25
                    }, {
                        scale: 1,
                        opacity: 1,
                        duration: 0.4,
                        ease: 'power3.out'
                    }, '-=0.3');

                    // High score
                    if (this.score >= this.scoreLimit) {
                        this.$nextTick(() => {
                            // Show separator
                            this.tl.to(this.$refs.sep, {
                                opacity: 0.4,
                                duration : 0.3,
                                ease : 'power3.out'
                            }, '-=0.3');

                            // Show bottom part heading
                            this.tl.fromTo(this.$refs.highScoreHeading, {
                                y: 20
                            }, {
                                y: 0,
                                opacity: 1,
                                duration: 0.3,
                                ease: 'power3.out'
                            }, '-=0.3');

                            // Show bottom part text
                            this.tl.fromTo(this.$refs.highScoreText, {
                                y: 20
                            }, {
                                y: 0,
                                opacity: 1,
                                duration: 0.3,
                                ease: 'power3.out'
                            }, '-=0.3');
                        })
                    }
                    // Low score
                    else {
                        this.$nextTick(() => {
                            this.tl.fromTo(this.$refs.lowScoreBtn, {
                                y: 20
                            }, {
                                y: 0,
                                opacity: 1,
                                duration: 0.3,
                                ease: 'power3.out'
                            }, '-=0.3');
                        });
                    }

                    this.$nextTick(() => {
                        // Show separator
                        this.tl.to(this.$refs.sep2, {
                            opacity: 0.4,
                            duration : 0.3,
                            ease : 'power3.out'
                        });

                        // Show bottom part heading
                        this.tl.fromTo(this.$refs.contentEnd, {
                            y: 20
                        }, {
                            y: 0,
                            opacity: 1,
                            duration: 0.3,
                            ease: 'power3.out'
                        });
                    });
                }
            }
        },

        mounted() {
            // Hide the heading lines
            gsap.set(document.querySelectorAll('.poll-result__line'), {
                y : 100
            });
        },

        methods: {
            scrollToExtra() {
                // Scroll to extra
                gsap.to(window, {
                    scrollTo: this.$el.querySelector('.poll-result__content').getBoundingClientRect().bottom,
                    duration: 0.5,
                    ease: 'power3.out'
                });
            },

            restartPoll() {
                // Reset position for all elements
                gsap.to([this.$refs.score, this.$refs.slash, this.$refs.maxScore, this.$refs.highScoreHeading, this.$refs.highScoreText, this.$refs.lowScoreBtn, this.$refs.text], {
                    y: 20,
                    opacity: 0
                });

                gsap.to([this.$refs.label, this.$refs.sep], {
                    opacity: 0
                });

                gsap.to(this.$el.querySelectorAll('.poll-result__line'), {
                    y: 100
                });

                gsap.to(this.$refs.restartBtn, {
                    scale: 1.25,
                    opacity: 0
                });

                this.$refs.clipboardResult.innerHTML = '';

                // Emit an event to restart the poll
                this.$emit('restart-poll');
            },

            goToPharma() {
                // Emit an event to say that we're done with the result
                this.$emit('result-done')
            },

            copyToClipboard(content) {
                // Check permissions if copy is available
                navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
                    // if it is
                    if (result.state === "granted" || result.state === "prompt") {
                        // Write in clipboard
                        navigator.clipboard.writeText(content);
                    }

                    // Show message if success/error
                    this.$refs.clipboardResult.innerHTML =
                        (result.state === "granted" || result.state === "prompt")
                            ? 'Copié dans le presse-papier !'
                            : 'Impossible de copier dans le presse papier'
                    ;
                });
            }
        }
    }
</script>

<style lang="scss">
    .poll-result {
        &__content {
            padding-top: 3.9375rem;

            @screen md {
                padding-top: 6.4275rem;
            }
        }

        &__line {
            @apply overflow-hidden;
        }
    }
</style>
