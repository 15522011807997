
<template>
    <div class="relative my-10 lg:my-20 h-max">
        <Carousel ref="carousel" :settings="settings">
            <Slide
                v-for="(slide, key) in slides"
                :key="key"
            >
                <span class="px-6 lg:px-8 w-full h-full">
                    <span class="border-t border-white flex flex-col text-center pt-4">
                        <p class="h5" v-html="slide.text"></p>
                    </span>
                </span>
            </Slide>
        </Carousel>
        <button @click="$refs.carousel.prev()" class="absolute top-1/2 -mt-6 -left-2 lg:hidden chevron-btn chevron-btn--left"></button>
        <button @click="$refs.carousel.next()" class="absolute top-1/2 -mt-6 -right-2 lg:hidden chevron-btn chevron-btn--right"></button>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
    name: 'CounterCarousel',

    props:{
       slides: {
           type: Array,
           required: true,
       },
    },

    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            settings: {
                itemsToShow: 1,
                wrapAround: true,
                breakpoints: {
                    1024: {
                        itemsToShow: 3,
                        wrapAround: false,
                    },
                }
            }
        }
    }
}
</script>
