<template>
    <div class="health-professional-orientation">
        <div class="health-professional-orientation__container min-h-screen background-gradient relative flex flex-col justify-center items-center py-32 lg:py-36 px-4">
            <div class="max-w-3xl mx-auto">
                <h2 ref="title" class="text-white text-center">
                    <em>Combien de résultats</em><br/>
                    souhaitez-vous renseigner ?
                </h2>
                <p ref="text" class="body-1 mt-6 text-white text-center">Pour rappel, vous pouvez saisir ici plusieurs résultats de votre campagne en une seule fois, ou bien saisir les résultats au fur et à mesure de chacun des patients testés.</p>
                <div class="flex flex-row justify-center items-center mt-4">
                    <button class="btn btn--rounded btn--ghost-white" @click.prevent="$emit('orientationDone', 1)">Plusieurs patients</button>
                    <button class="btn btn--rounded btn--ghost-white ml-3" @click.prevent="$emit('orientationDone', 0)">Un patient</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HealthProfessionalOrientation'
    }
</script>
