<template>
    <div class="qr-result">
        <div class="qr-result__content relative min-h-screen flex flex-col items-center justify-start md:justify-center background-gradient pb-8 md:pb-0">
            <div class="h-full text-center text-white px-4">
                <button
                    ref="restartBtn"
                    class="btn-picto btn-picto--white mt-4 lg:mt-0 lg:absolute lg:top-8 lg:left-10 z-20"
                    @click="restartPoll"
                >
                    Faire un nouveau test
                </button>
                <div class="max-w-3xl mt-10 lg:mt-0 mx-auto flex flex-col items-center">
                    <h2 ref="heading" class="max-w-lg mx-auto mt-3">
                        <div class="overflow-hidden">
                            <div class="qr-result__line">
                                <em>Présentez votre QR code</em>
                            </div>
                        </div>
                        <div class="overflow-hidden">
                            <div class="qr-result__line">
                                à votre professionnel de santé
                            </div>
                        </div>
                    </h2>


                    <div class="flex flex-col md:flex-row md:items-center">
                        <div v-if="pharma" class="phar-map__pharma-btn flex-shrink-0 py-4 px-8 text-white border border-white border-opacity-30 rounded-lg text-center mt-8">
                            <span class="block relative lead">{{ pharma.title }}</span>
                            <span class="block relative body-2">{{ pharma.address }}<br/>{{ pharma.postal_code }} {{ pharma.city}}</span>
                        </div>

                        <p class="body-2 mt-6 md:text-left md:ml-12">
                            Rendez-vous dans la pharmacie que vous avez sélectionné et présentez le QR code à votre pharmacien (préalablement imprimé ou téléchargé sur votre téléphone) pour obtenir un bon gratuit pour effectuer un test de glycémie.
                        </p>

                    </div>

                    <a v-if="pdf" :href="pdf" target="_blank" class="btn btn--white mt-8 md:mt-10">Télécharger le QR code et mes résultats</a>
                </div>
                <div class="max-w-4xl mx-auto pb-12">
                    <hr class="my-6 md:my-12 border-t border-white w-full" />
                    <div  class="flex flex-col md:flex-row text-center md:text-left">
                        <div class="md:w-1/2">
                            <h5>
                                <em>Diffusez ce test,</em><br/>
                                partagez-le à vos proches
                            </h5>
                            <p class="body-2 mt-3">
                                Vous permettrez ainsi de sensibiliser le plus grand nombre, en partageant ce test par mail, sur WhatsApp ou en copiant le lien du site.
                            </p>
                            <div class="flex justify-center items-center md:justify-start mt-4">
                                <p class="overline text-white mr-6 md:mr-8">Partager</p>
                                <a href="mailto:?subject=Mon test diabète - Faites le test avec nos 9 questions…&amp;body=https://www.montestdiabete.fr/" target="_blank" class="share-btn">
                                    <img src="~@/static/images/icons/email.svg" alt="Email"/>
                                </a>
                                <a href="whatsapp://send?text=https://www.montestdiabete.fr/" target="_blank" class="share-btn ml-3 md:ml-4">
                                    <img src="~@/static/images/icons/whatsapp.svg" alt="WhatsApp" />
                                </a>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.montestdiabete.fr/" target="_blank" class="share-btn ml-3 md:ml-4">
                                    <img src="~@/static/images/icons/facebook.svg" alt="Facebook" />
                                </a>
                                <div class="relative ml-3 md:ml-4">
                                    <button class="share-btn" @click.prevent="copyToClipboard('https://www.montestdiabete.fr/')">
                                        <img src="~@/static/images/icons/copy.svg" alt="Copier" />
                                    </button>
                                    <p ref="clipboardResult" class="caption uppercase absolute -bottom-6 left-1/2 transform -translate-x-1/2 whitespace-nowrap"></p>
                                </div>
                            </div>
                        </div>
                        <div class="md:w-1/2 mt-10 md:mt-0 md:ml-6">
                            <h5>
                                <em>Vous souhaitez</em><br/>
                                en savoir plus sur le diabète ?
                            </h5>
                            <p class="body-2 mt-3">
                                Inscrivez-vous gratuitement à notre communauté Bien vivre mon diabète et recevez une fois par mois nos conseils pour gérer au mieux le diabète.
                            </p>
                            <a href="https://go.roche.com/communaute-bienvivremondiabete " target="_blank" class="btn btn--white mt-4">Recevoir des informations de Roche</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pharma: { type: Object, default: () => {} },
            pdf: { type: String, default: '' }
        },

        methods: {
            restartPoll() {
                this.$refs.clipboardResult.innerHTML = '';

                this.$emit('restart-poll');
            },

            copyToClipboard(content) {
                // Check permissions if copy is available
                navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
                    // if it is
                    if (result.state === "granted" || result.state === "prompt") {
                        // Write in clipboard
                        navigator.clipboard.writeText(content);
                    }

                    // Show message if success/error
                    this.$refs.clipboardResult.innerHTML =
                        (result.state === "granted" || result.state === "prompt")
                            ? 'Copié dans le presse-papier !'
                            : 'Impossible de copier dans le presse papier'
                    ;
                });
            }
        }
    }
</script>

<style lang="scss">
    .qr-result {
        &__content {
            padding-top: 3.9375rem;

            @screen md {
                padding-top: 6.4275rem;
            }
        }
    }
</style>
