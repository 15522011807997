<template>
    <div class="extra-imc pt-12 pb-8 md:pt-20 md:pb-32">
        <div class="max-w-5xl mx-auto px-4">
            <div class="flex flex-col items-center text-center">
                <h5>Formule de l'IMC</h5>
                <img :src="require('@/static/images/imc/formule.svg')" class="mt-4" />
                <h5 class="mt-12">Trouvez votre IMC</h5>
            </div>

            <div class="extra-imc__accordions mt-8">
                <accordion
                    v-for="(accordion, key) in accordions"
                    :key="key"
                    :small="true"
                >
                    <template #opener>
                        <p class="lead">{{ accordion.opener }}</p>
                    </template>
                    <template #content>
                        <div class="flex justify-center pt-4 md:pt-6">
                            <img :src="require(`@/static/images/imc/${accordion.content.mobileImage}`)" class="md:hidden" />
                            <img :src="require(`@/static/images/imc/${accordion.content.desktopImage}`)" class="hidden md:block" />
                        </div>
                    </template>
                </accordion>
            </div>

            <p class="caption text-grey-40 mt-8">
                Sources : <a href="https://calculersonimc.fr/grille-et-tableau-imc.html" target="_blank">https://calculersonimc.fr/grille-et-tableau-imc.html.</a><br/>
                Lindström J, Tuomilehto J. The diabetes risk score. A practical tool to predict type 2 diabetes risk. Diabetes Care 2003;26(3):725-31.
            </p>
        </div>
        <div class="flex justify-center mt-8 md:mt-20" @click="scrollUp">
            <button class="btn-up">Haut de page</button>
        </div>
    </div>
</template>

<script>
    import Accordion from "@/app/components/Accordion";
    import { gsap } from '@lahautesociete/gsap';

    export default {
        components: { Accordion },

        data() {
            return {
                accordions: [
                    {
                        opener: "Poids: 50 à 60kg",
                        content: {
                            mobileImage: 'mobile-50.svg',
                            desktopImage: 'desktop-50.svg'
                        }
                    },
                    {
                        opener: "Poids: 65 à 80kg",
                        content: {
                            mobileImage: 'mobile-65.svg',
                            desktopImage: 'desktop-65.svg'
                        }
                    },
                    {
                        opener: "Poids: 85 à 100kg",
                        content: {
                            mobileImage: 'mobile-85.svg',
                            desktopImage: 'desktop-85.svg'
                        }
                    },
                    {
                        opener: "Poids: 105 à 115kg",
                        content: {
                            mobileImage: 'mobile-105.svg',
                            desktopImage: 'desktop-105.svg'
                        }
                    }
                ]
            }
        },

        methods: {
            scrollUp() {
                gsap.to(window, {
                    scrollTo: 0,
                    duration: 0.3,
                    ease: 'power2.out'
                })
            }
        }
    }
</script>
