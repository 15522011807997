<template>
    <div class="phar-map">
        <div class="phar-map__form relative min-h-screen background-gradient text-white flex flex-col justify-center">
            <button
                ref="restartBtn"
                class="btn-picto btn-picto--white absolute top-20 lg:top-8 left-1/2 -translate-x-1/2 lg:transform-none lg:left-10 z-20 whitespace-nowrap"
                @click="restartPoll"
            >
                Faire un nouveau test
            </button>
            <div class="max-w-2xl mx-auto px-4">
                <h2 class="text-center">
                    <em>Trouver le professionnel de santé partenaire</em><br/>
                    le plus proche
                </h2>
                <div class="phar-map__address-form flex flex-col md:flex-row justify-center items-center mt-8 md:mt-10">
                    <div class="relative w-full md:w-auto">
                        <input
                            ref="addressField"
                            type="text"
                            v-model="address"
                            placeholder="Entrez votre adresse (rue, voie, place...)"
                            name="address"
                            class="phar-map__input-text body-2 focus:border focus:border-white"
                            required
                            autofocus
                            @keydown="keydownHandler"
                        />
                        <img :src="require(`@/static/images/icons/pin.svg`)" class="absolute top-1/2 left-2 transform -translate-y-1/2" />
                    </div>
                    <button
                        :class="{'btn--white-no-hover-plain': address === ''}"
                        class="btn btn--white btn--white-no-hover relative mt-4 md:mt-0 md:-ml-4"
                        @click="setMap"
                    >
                        Rechercher
                    </button>
                </div>
            </div>
        </div>
        <div ref="mapContainer" class="phar-map__map-container h-0 overflow-hidden">
            <div ref="mapInner" class="phar-map__map-inner flex flex-col md:flex-row md:items-end pt-6 pb-16 md:py-10">
                <div class="phar-map__map-center w-full md:w-8/12">
                    <div class="phar-map__map">
                        <div class="flex flex-col md:flex-row justify-center items-center text-center">
                            <p class="lead opacity-70">{{ address }}</p>
                            <button class="btn-text btn-text--black uppercase mt-4 md:mt-0 md:ml-4" @click="focusField">Modifier</button>
                        </div>
                        <div id="map" class="mt-4" />
                    </div>
                </div>

                <div class="phar-map__pharma-list md:w-4/12 flex flex-col items-center md:items-start mt-8 md:mt-0 md:ml-12 px-4 md:px-0">
                    <template v-for="(pharma, index, key) in selectedPharmas">
                        <button
                            v-if="pharma.title && pharma.address"
                            :key="key"
                            :class="{
                                'text-grey-40 border border-grey-80': selectedPharma !== pharma,
                                'text-black border-0 border-gradient': selectedPharma === pharma,
                                'mt-4': index > 0
                            }"
                            class="phar-map__pharma-btn py-4 px-8 rounded-lg text-left cursor-pointer"
                            @click="selectPharma(pharma)"
                        >
                            <span class="block relative lead">{{ pharma.title }}</span>
                            <span class="block relative body-2">
                                {{ pharma.address }}<br/>
                                {{ pharma.postal_code }} {{ pharma.city }}<br/>
                                <button
                                    v-if="selectedPharma !== null && selectedPharma === pharma"
                                    class="phar-map__choose-pharma-btn btn-text btn-text--black my-4 cursor-pointer"
                                    @click="submitPharma"
                                    :disabled="selectedPharma === null ? 'disabled' : false"
                                >
                                    Choisir cet établissement
                                </button>
                            </span>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import '@/static/styles/leaflet.css';
    import 'leaflet/dist/leaflet';
    import axios from 'axios';
    import gsap from '@lahautesociete/gsap';
    import { ScrollToPlugin } from '@lahautesociete/gsap/ScrollToPlugin';
    gsap.registerPlugin(ScrollToPlugin);

    export default {
        props: {
            pharmas: { type: Array, default: () => {} },
            isCurrentStep: { type: Boolean, default: false }
        },

        data() {
            return {
                map: null,
                address: '',
                mapOpened: false,
                selectedPharmas: [],
                selectedPharma: null,
                position: null,
                apiKey: '3c3aac6a20634105bb7cc0bb2863cfef'
            }
        },

        watch: {
            isCurrentStep() {
                if (this.isCurrentStep) {
                    // Prevent gray map
                    setTimeout(function () {
                        window.dispatchEvent(new Event('resize'));
                    }, 1000);
                }
            },
        },

        mounted() {
            // Map init
            // eslint-disable-next-line
            this.map = L.map('map').setView([46.599147, 2.984505], 6);
            // eslint-disable-next-line
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);

            // Prevent gray map
            setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
            }, 1000);

            // eslint-disable-next-line
            this.iconOff = L.icon({
                iconUrl: require('@/static/images/icons/marker-off.svg'),
                iconSize: [41, 54],
                iconAnchor: [20.5, 54]
            });

            // eslint-disable-next-line
            this.iconOn = L.icon({
                iconUrl: require('@/static/images/icons/marker-on.svg'),
                iconSize: [41, 54],
                iconAnchor: [20.5, 54]
            });
        },

        methods: {
            async setMap() {
                // Get the position of the address
                this.position = await this.getPosition();

                // Get the three closest pharma
                this.selectedPharmas = this.getThreeClosest(this.pharmas,this.position[0], this.position[1]);

                // Put the markers and set map position
                this.putMarkers();

                // Fly to bounds set by markers
                this.setBounds();

                // Show the map
                this.openMap();
            },

            restartPoll() {
                this.address = '';
                this.selectedPharmas = [];
                this.selectedPharma = null;

                this.$emit('restart-poll');
            },

            focusField() {
                // Scroll top
                gsap.to(window, {
                    scrollTo: 0,
                    duration: 0.2,
                    ease: 'power2.out'
                });

                // Set focus on field
                this.$refs.addressField.focus();
            },

            openMap() {
                if (this.address) {
                    this.$nextTick(() => {
                        gsap.to(this.$refs.mapContainer, {
                            height: this.$refs.mapInner.clientHeight,
                        });

                        // Scroll to map
                        gsap.to(window, {
                            scrollTo: window.innerHeight,
                            duration: 0.3,
                            ease: 'power2.out'
                        });
                    })
                }
            },

            async getPosition() {
                // Config for the geoapify call
                const config = {
                    method: 'get',
                    url: `https://api.geoapify.com/v1/geocode/search?text=${this.address}&apiKey=${this.apiKey}`,
                    headers: { }
                };

                // Axios call for geoapify
                const coord = await axios(config)
                    .then((response) => {
                        const firstResult = response.data.features[0];
                        const coordinates = firstResult.geometry.coordinates.reverse();
                        this.map.flyTo(coordinates, 13);
                        return coordinates
                    })
                    .catch((error) => {
                        console.error(error);
                        return null;
                    });

                return coord;
            },

            // Get the three closest elements to destLat and destLng in places
            getThreeClosest(places, destLat, destLng) {
                // Go through the places
                places.forEach((pharma) => {
                    // Add a property distance with the distance between dest and the place
                    pharma.distance = this.distance(pharma.lat, pharma.lng, destLat, destLng, 'K');
                });

                // Sort the places within the distance value
                places = places.sort((a, b) => {
                  return a.distance - b.distance
                });

                // Return the three first/smallest
                return places.slice(0, 3);
            },

            putMarkers() {
                // Define markers
                this.markers = [];

                // Go through pharmas
                this.selectedPharmas.forEach((pharma) => {
                    // Create marker and add it to markers
                    // eslint-disable-next-line
                    const marker = L.marker([pharma.lat, pharma.lng], { icon: this.iconOff, title: pharma.title, alt: pharma.title}).addTo(this.map);

                    this.markers.push(marker);

                    marker.on('click', () => {
                        this.selectPharma(pharma)
                    });
                });


            },

            setBounds() {
                // For each markers
                this.markers.forEach((marker, index) => {
                    // if first one
                    if (index === 0) {
                        // Init bounds with first marker
                        // eslint-disable-next-line
                        this.bounds = L.latLngBounds(marker.getLatLng());
                    } else {
                        // Extend the bouonds
                        this.bounds.extend(marker.getLatLng());
                    }
                });

                // Set max bounds
                // this.map.setMaxBounds(this.bounds);

                // Go to the bounds
                this.map.fitBounds(this.bounds);
            },

            selectPharma(pharma) {
                this.selectedPharma = pharma;

                this.markers.forEach((marker) => {
                    if (marker.getLatLng().lat === parseFloat(pharma.lat) && marker.getLatLng().lng === parseFloat(pharma.lng)) {
                        marker.setIcon(this.iconOn);
                    } else {
                        marker.setIcon(this.iconOff);
                    }
                });
            },

            submitPharma() {
                this.$emit('pharma-selected', this.selectedPharma);
            },

            keydownHandler(pEvent) {
                if (pEvent.code === 'Enter') {
                    this.setMap();
                }
            },

            // Return the distance between two sets of lat/lng
            distance(lat1, lon1, lat2, lon2, unit) {
                const radlat1 = Math.PI * lat1/180
                const radlat2 = Math.PI * lat2/180
                const theta = lon1-lon2
                const radtheta = Math.PI * theta/180
                let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                if (dist > 1) {
                    dist = 1;
                }
                dist = Math.acos(dist)
                dist = dist * 180/Math.PI
                dist = dist * 60 * 1.1515
                if (unit=="K") { dist = dist * 1.609344 }
                if (unit=="N") { dist = dist * 0.8684 }
                return dist
            }
        }
    }
</script>

<style lang="scss">
    .phar-map {

        &__input-text {
            @apply w-full h-12 rounded text-white pl-10 pr-6 py-2 outline-none;
            background: rgba(0, 0, 0, 0.1);

            @screen md {
                width: 22.125rem;
            }
        }

        #map {
            @apply w-full;
            height: 32rem;

        }

        &__map {
            img {
                max-height: inherit;
            }
        }

        &__map-inner {
            @apply mx-auto;
            max-width: 70.125rem;
        }

        &__pharma-btn {
            width: 18.125rem;
        }

        &__submit-btn {
            &:focus {
                @apply border border-white;
            }
        }

        &__choose-pharma-btn:disabled {
            @apply opacity-20 cursor-auto;
        }

        .border-gradient {
            @apply relative bg-gradient;
            padding: 18px 34px;

            &:before {
                @apply block bg-white absolute inset-0 m-0.5 rounded-md;
                content: '';
                width: calc(100% - 4px);
                height: calc(100% - 4px);
            }
        }

        ::-webkit-input-placeholder,
        ::placeholder {
            @apply text-white;
        }

        .leaflet-control-attribution.leaflet-control {
            display: none !important;
        }
    }
</style>
