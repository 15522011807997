<template>
    <div class="patient-poll min-h-screen relative">
        <div ref="bg" class="patient-poll__bg h-screen absolute top-0 left-0 right-0" />
        <stepper
            :steps="steps"
            count-text="Question ${n}"
            previous-step-text="Question précédente"
            :restart="restart"
            class="patient-poll__stepper relative"
            @stepper-done="stepperDone"
            @restart-done="restartDone"
        />

        <poll-result
            :score="score"
            :max-score="maxScore"
            class="patient-poll__result relative hidden opacity-0"
            @restart-poll="restartPoll"
            @result-done="goToPharma"
        />

        <phar-map
            :pharmas="healthProfessionals"
            :is-current-step="currentStep === 'map'"
            class="patient-poll__map relative hidden opacity-0"
            @pharma-selected="pharmaSelected"
            @restart-poll="restartPoll"
        />

        <QrResult
            :pharma="selectedPharma"
            :pdf="pdfUrl"
            class="patient-poll__qr-result hidden opacity-0"
            @restart-poll="restartPoll"
        />
    </div>
</template>

<script>
    import Stepper from '@/app/components/Stepper';
    import PollResult from '@/app/components/PollResult';
    import PharMap from '@/app/components/PharMap';
    import QrResult from '@/app/components/QrResult';
    import gsap from '@lahautesociete/gsap';

    import axios from 'axios';

    export default {
        name: "PatientPoll",

        props: {
            siteUrl: { type: String, required: true },
            sectionUid: { type: String, required: true },
            healthProfessionals: { type: Array,  required: true },
        },

        components: {
            Stepper,
            PollResult,
            PharMap,
            QrResult
        },

        data() {
            return {
                score: -1,
                maxScore: 26,
                restart: false,
                currentStep: '',
                selectedPharma: null,
                pollResponseUid: null,
                steps: [
                    {
                        type: "SingleChoiceStep",
                        question: "Quel âge avez-vous ?",
                        additionalText: "Votre participation à ce test n'implique le traitement d'aucune de vos données personnelles. Votre navigation est totalement privée.",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][age]",
                                id: "age-1",
                                text: "Moins de 35 ans",
                                value: "Moins de 35 ans",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][age]",
                                id: "age-2",
                                text: "Entre 35 et 44 ans",
                                value: "Entre 35 et 44 ans",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][age]",
                                id: "age-3",
                                text: "Entre 45 et 54 ans",
                                value: "Entre 45 et 54 ans",
                                score: 2
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][age]",
                                id: "age-4",
                                text: "Entre 55 et 64 ans",
                                value: "Entre 55 et 64 ans",
                                score: 3
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][age]",
                                id: "age-5",
                                text: "Plus de 64 ans",
                                value: "Plus de 64 ans",
                                score: 4
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Un membre de votre famille est-il atteint de diabète ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][familyMember]",
                                id: "family-1",
                                text: "Non, personne est atteint de diabète",
                                value: "Non",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][familyMember]",
                                id: "family-2",
                                text: "Oui, un membre de famille proche : père, mère, enfant, frère, soeur",
                                value: "Oui, famille éloignée",
                                score: 5
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][familyMember]",
                                id: "family-3",
                                text: "Oui, un membre de famille éloignée : grand-parent, tante, oncle, cousin·e",
                                value: "Oui, famille proche",
                                score: 3
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Vous êtes ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][gender]",
                                id: "gender-1",
                                icon: "man.svg",
                                text: "Un homme",
                                value: "Homme",
                                score: 0,
                                nextQuestionChoicesIndex: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][gender]",
                                id: "gender-2",
                                icon: "woman.svg",
                                text: "Une femme",
                                value: "Femme",
                                score: 0,
                                nextQuestionChoicesIndex: 1
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Quel est votre tour de taille au niveau du nombril ?",
                        choices: [
                            [
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-1",
                                    text: "Moins de 94cm",
                                    value: "Moins de 94cm",
                                    score: 0
                                },
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-2",
                                    text: "Entre 94 - 102cm",
                                    value: "Entre 94 - 102cm",
                                    score: 3
                                },
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-3",
                                    text: "Plus de 102cm",
                                    value: "Plus de 102cm",
                                    score: 4
                                }
                            ],
                            [
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-1",
                                    text: "Moins de 80cm",
                                    value: "Moins de 80cm",
                                    score: 0
                                },
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-2",
                                    text: "Entre 80 - 88cm",
                                    value: "Entre 80 - 88cm",
                                    score: 3
                                },
                                {
                                    name: "fields[pollResponseFindriscTest][0][waist]",
                                    id: "waist-3",
                                    text: "Plus de 88cm",
                                    value: "Plus de 88cm",
                                    score: 4
                                }
                            ]
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Pratiquez-vous au moins 30 minutes d’activité physique par jour ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][sport]",
                                id: "sport-1",
                                text: "Oui",
                                value: "Oui",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][sport]",
                                id: "sport-2",
                                text: "Non",
                                value: "Non",
                                score: 2
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "À quelle fréquence mangez-vous des fruits et légumes ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][vegetables]",
                                id: "vegetables-1",
                                text: "Tous les jours",
                                value: "Tous les jours",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][vegetables]",
                                id: "vegetables-2",
                                text: "Pas tous les jours",
                                value: "Pas tous les jours",
                                score: 1
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Vous a-t-on déjà prescrit des médicaments contre l’hypertension ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][hypertensionDrugs]",
                                id: "hypertension-drugs-1",
                                text: "Oui",
                                value: "Oui",
                                score: 2
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][hypertensionDrugs]",
                                id: "hypertension-drugs-2",
                                text: "Non",
                                value: "Non",
                                score: 0
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Vous a-t-on déjà découvert un taux de sucre sanguin élevé ?",
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][bloodSugar]",
                                id: "blood-sugar-1",
                                text: "Oui",
                                value: "Oui",
                                score: 5
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][bloodSugar]",
                                id: "blood-sugar-2",
                                text: "Non",
                                value: "Non",
                                score: 0
                            }
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        question: "Quel est votre IMC - Indice de Masse Corporelle ?",
                        resultButton: true,
                        choices: [
                            {
                                name: "fields[pollResponseFindriscTest][0][bodyMassIndex]",
                                id: "body-mass-index-1",
                                text: "Moins de 25 kg/m2",
                                value: "Moins de 25 kg/m2",
                                score: 0
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][bodyMassIndex]",
                                id: "body-mass-index-2",
                                text: "Entre 25 et 30 kg/m2",
                                value: "Entre 25 et 30 kg/m2",
                                score: 1
                            },
                            {
                                name: "fields[pollResponseFindriscTest][0][bodyMassIndex]",
                                id: "body-mass-index-3",
                                text: "Plus de 30 kg/m2",
                                value: "Plus de 30 kg/m2",
                                score: 3
                            }
                        ],
                        extraButtonLabel: "Je ne connais pas mon IMC",
                        extra: "ExtraIMC"
                    }
                ]
            }
        },

        computed: {
            pdfUrl() {
                return `${this.siteUrl}generate-pdf?pollResponseUid=${this.pollResponseUid}`;
            },
        },

        methods: {
            stepperDone(pValues) {
                // Save the values
                this.formValues = pValues;

                // Reinit the score
                this.score = 0;

                // Go through the values
                this.formValues.forEach((pItem) => {
                    // Add the score of each choice to global score
                    this.score += pItem[0].score;
                });

                // Hide the stepper
                gsap.to(this.$el.querySelector('.patient-poll__stepper'), {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3
                });

                // Show the result
                gsap.to(this.$el.querySelector('.patient-poll__result'), {
                    opacity: 1,
                    display: 'block',
                    duration: 0.3,
                    delay: 0.3
                });

                // Submit a first time
                this.submit();
            },

            restartPoll() {
                // Reset form values
                this.formValues = [];

                // Show the stepper
                gsap.to(this.$el.querySelector('.patient-poll__stepper'), {
                    opacity: 1,
                    display: 'block',
                    duration: 0.3
                });

                // Hide the results
                gsap.to(this.$el.querySelector('.patient-poll__result'), {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3,
                    delay: 0.3
                });

                // Hide the map
                gsap.to(this.$el.querySelector('.patient-poll__map'), {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3,
                    delay: 0.3
                });

                this.restart = true;
            },

            restartDone() {
                this.restart = false;
            },

            goToPharma() {
                // Show the stepper
                gsap.to(this.$el.querySelector('.patient-poll__stepper'), {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3
                });

                // Hide the results
                gsap.to(this.$el.querySelector('.patient-poll__result'), {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3,
                    delay: 0.3
                });

                // Hide the map
                gsap.to(this.$el.querySelector('.patient-poll__map'), {
                    opacity: 1,
                    display: 'block',
                    duration: 0.3,
                    delay: 0.3
                });

                this.currentStep = 'map';
            },

            pharmaSelected(pPharma) {
                // Keep the pharma
                this.selectedPharma = pPharma;

                // Submit the new data
                this.submit();

                // Go to final step
                this.showQr();
            },

            showQr() {
                // Show the stepper
                gsap.to([this.$el.querySelector('.patient-poll__stepper'), this.$el.querySelector('.patient-poll__result'), this.$el.querySelector('.patient-poll__map')] , {
                    opacity: 0,
                    display: 'none',
                    duration: 0.3
                });

                // Hide the map
                gsap.to(this.$el.querySelector('.patient-poll__qr-result'), {
                    opacity: 1,
                    display: 'block',
                    duration: 0.3,
                    delay: 0.3
                });
            },

            submit() {
                // Init payload
                const payload = new FormData();

                // Add the form informations
                payload.append('CRAFT_CSRF_TOKEN', window.Craft.csrfTokenValue);
                payload.append('action', 'guest-entries/save');
                payload.append('sectionUid', this.sectionUid);

                if (this.pollResponseUid) {
                    payload.append('entryUid', this.pollResponseUid);
                }

                // Add the form values
                if (this.formValues && this.formValues.length > 0) {
                    this.formValues.forEach((pItem) => {
                        payload.append(pItem[0].name, pItem[0].value);
                    });
                }

                // If we have a score
                if (this.score) {
                    payload.append('fields[pollResponseScore]', this.score);
                }

                // if we have a pharma
                if (this.selectedPharma) {
                    payload.append('fields[pollResponseHealthProfessional][]', this.selectedPharma.id);
                }

                // Submit
                axios.post(document.location.href, payload, {
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).then((response) => {
                    this.pollResponseUid = response.data.entry.title;
                }).catch((error) => {
                    console.error(error);
                });
            },
        }
    }
</script>
